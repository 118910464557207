// SETTINGS FOR EACH FRAMEWORK
// ---------------------------

// Twitter bootstrap settings.
$grid-columns: 12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
        xs: 0,
  // Small screen / phone
        sm: 576px,
  // Medium screen / tablet
        md: 768px,
  // Large screen / desktop
        lg: 992px,
  // Extra large screen / wide desktop
        xl: 1200px
);

$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1200px
);

// Import libraries.
@import "lib/bootstrap/scss/bootstrap-grid";
@import "lib/bootstrap/scss/utilities/borders";
@import "lib/bootstrap/scss/utilities/spacing";

